<template>
    <div style="margin-bottom: 7rem !important">
        <h1 v-if="!id">{{ form.id ? 'Ändra' : 'Skapa' }} widgets</h1>
        <hr />
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-dashboardId"
                          label="Dashboard"
                          label-for="input-dashboardId">
                <b-form-select id="input-dashboardId"
                              v-model="form.dashboardId"
                              :options="dashboardOptions"
                              type="text"
                              placeholder="Välj dashboard"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-dashboardId-help-block"
                               required/>
                <b-form-text id="input-dashboardId-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('DashboardId')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-title" label="Titel" label-for="input-title">
                <b-form-input id="input-title"
                              v-model="form.title"
                              type="text"
                              placeholder="Ange titel"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-title-help-block"
                              required />
                <b-form-text id="input-title-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Title')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!--Dropdown-->
            <b-form-group id="input-group-componentName" label="Komponentnamn" label-for="input-componentName">
                <b-form-select id="input-componentName"
                               v-model="form.componentName"
                               type="text"
                               placeholder="Ange komponentnamn"
                               :options="componentNameOptions"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-componentName-help-block"
                               required />
                <b-form-text id="input-componentName-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('ComponentName')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!--Dropdown-->
            <b-form-group id="input-group-cols" label="Kolumnstorlek" label-for="input-cols">
                <b-form-select id="input-cols"
                               v-model="form.cols"
                               type="text"
                               placeholder="Ange kolumnstorlek"
                               :options="colOptions"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-cols-help-block"
                               required />
                <b-form-text id="input-cols-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Cols')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sortOrder" label="Sorteringsordning" label-for="input-sortOrder">
                <b-form-input id="input-sortOrder"
                              v-model="form.sortOrder"
                              type="number"
                              min="1"
                              max="2,147,483,647"
                              placeholder="Ange sorteringsordning"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-sortOrder-help-block"
                              required />
                <b-form-text id="input-sortOrder-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SortOrder')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sqlQuery" label="SQLQuery" label-for="input-sqlQuery">
                <b-form-textarea id="input-sqlQuery"
                                 v-model="form.sqlQuery"
                                 type="text"
                                 placeholder="Ange SQLQuery"
                                 :disabled="isSubmitting"
                                 autocomplete="off"
                                 aria-describedby="input-sqlQuery-help-block"
                                 rows="10"
                                 required />
                <b-form-text id="input-sqlQuery-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SQLQuery')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-settings" label="Inställningar" label-for="input-settings">
                <b-form-textarea id="input-settings"
                                 v-model="form.settings"
                                 type="text"
                                 placeholder="Ange inställningar"
                                 :disabled="isSubmitting"
                                 autocomplete="off"
                                 aria-describedby="input-settings-help-block"
                                 rows="10"
                                 required />
                <b-form-text id="input-settings-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Settings')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-row class="m-0">
                <b-col md="4" sm="12">
                    <b-form-group id="input-group-isActive"
                                  label="Aktiv"
                                  label-for="input-isActive">
                        <b-form-checkbox id="input-isActive"
                                         v-model="form.isActive"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isActive-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsActive')" :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>

                <b-col md="4" sm="12">
                    <b-form-group id="input-group-isDynamic"
                                  label="Dynamisk"
                                  label-for="input-isDynamic">
                        <b-form-checkbox id="input-isDynamic"
                                         v-model="form.isDynamic"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isDynamic-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsDynamic')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <b-alert :show="successMessage.length > 0"
                     variant="success"
                     class="mb-0 mt-4">
                <h6 class="mb-0 text-center">
                    <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                </h6>
            </b-alert>

            <b-button v-b-tooltip.hover
                      variant="primary"
                      class="float-right mt-4"
                      :class="{ 'btn-block': id }"
                      :disabled="isSubmitting"
                      type="submit"
                      :title="isSubmitting ? 'Sparar...' : ''">
                <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
            </b-button>
        </b-form>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { get, post } from '@/helpers/api';

    export default {
        name: 'SaveWidget',
        components: {

        },
        props: {
            id: Number
        },
        data() {
            return {
                form: {
                    id: null,
                    dashboardId: null,
                    title: '',
                    componentName: '',
                    cols: 4,
                    sortOrder: 999,
                    isActive: false,
                    isDynamic: true,
                    sqlQuery: '',
                    settings: ''
                },
                dashboardOptions: [],
                componentNameOptions: [
                    { value: 'TableWidget', text: 'TableWidget' },
                    { value: 'ChartWidget', text: 'ChartWidget' },
                ],
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                colOptions: Array.from({ length: 12 }, (v, k) => k + 1).map((num) => ({
                    value: num,
                    text: num.toString(),
                })),
            };
        },
        computed: {
            isEditMode() {
                return this.form.id > 0;
            }
        },

        async mounted() {

            this.getData();
            // Get widget if necessary.
            this.form.id = typeof this.id !== 'undefined' ? this.id : 0;

            if (this.isEditMode) {
                await get('Widget', `Get?id=${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;
                    })
                    .catch((x) => {
                        alert(x.data);
                    });
            }
        },
        methods: {
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                await post('Widget', 'Save', this.form)
                    .then((x) => {
                        if (x.data) {
                            // If this is the first save (form.id is null), set form.id to entityId
                            if (!this.form.id && x.data.entityId) {
                                this.form.id = x.data.entityId;
                            }
                            this.successMessage = `${this.form.id ? 'Uppdaterade' : 'Skapade'} ${this.form.title} ${this.form.componentName}`;
                        }
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });

                this.isSubmitting = false;

                // On created .
                if (!this.validationErrors && this.successMessage) {
                    this.onCreated();
                }
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    dashboardId: null,
                    title: '',
                    componentName: '',
                    cols: null,
                    sordOrder: null,
                    isActive: false,
                    isDynamic: false,
                    sqlQuery: '',
                    settings: ''
                };
                this.validationErrors = null;
            },
            async confirm() {
                this.$bvModal
                    .msgBoxConfirm('Förändringar har skett', {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        return confirmed;
                    });
            },
            async getData() {
                await get('Dashboard', 'GetAll')
                    .then((x) => {
                        this.dashboards = x.data;

                        // Map dashboardOptions with id and name
                        this.dashboardOptions = this.dashboards.map((dashboard) => ({
                            value: dashboard.id,
                            text: dashboard.name,
                        }));
                    })
                    .catch((x) => {
                        console.log("Fel i hämtning av widgets");
                    });
            },
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
