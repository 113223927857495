<template>
    <div>
        <b-row>
            <b-col sm="8" xs="12">
                <h1>Widgets</h1>
            </b-col>
            <b-col sm="4" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary" @click="add"> Lägg till </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-form-checkbox v-model="onlyShowActiveWidgets">
                        Visa enbart aktiva
                    </b-form-checkbox>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
                </b-form-group>
            </b-col>

            <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-if="isReady"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>
        <!--TABLE-->
        <b-table v-if="isReady"
                 :items="filteredWidgets"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['title', 'dashboardName', 'componentName']"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isBusy"
                 stacked="sm"
                 show-empty
                 small
                 @filtered="onFiltered"
                 @context-changed="onContextChanged">

            <template #cell(title)="row">
                <div class="overflow-hidden">
                    {{ row.item.title }}
                </div>
            </template>
            <template #cell(dashboardName)="row">
                <div class="overflow-hidden">
                    <img v-if="row.item.fileSymbolId"
                         style="height: 1.5rem"
                         :src="getUrl(row.item.fileSymbolId)" />
                    {{ row.item.dashboardName }}
                </div>
            </template>
            <template #cell(sortOrder)="row">
                <div class="overflow-hidden">
                    {{ row.item.sortOrder }}
                </div>
            </template>

            <template #cell(componentName)="row">
                <div class="overflow-hidden">
                    {{ row.item.componentName }}
                </div>
            </template>
            <template #cell(cols)="row">
                <div class="overflow-hidden">
                    {{ row.item.cols }}
                </div>
            </template>
            <template #cell(displayActiveText)="row">
                <div class="overflow-hidden">
                    {{ row.item.displayActiveText }}
                </div>
            </template>
            <template #cell(actions)="row">
                <!-- Open sidebar. -->
                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                   icon="pen-square"
                                   size="lg"
                                   role="button"
                                   @click="edit(row.item)" />
                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="edit(row.item)">
                    Mer
                </b-button>
                <!-- ./ Open sidebar. -->
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>
        <!--SIDEBAR-->
        <GenericSidebar :visible.sync="sidebar.visible"
                        :is-edit-mode.sync="sidebar.isEditMode"
                        :fields="sidebar.fields"
                        :item="sidebar.selected"
                        width-when-editing="800px"
                        @update:updateData="getData">
            <template v-slot:header>
                <span v-if="sidebar.selected">
                    {{ sidebar.selected.title }}
                </span>
            </template>
            <template v-slot:edit>
                <b-button v-if="!sidebar.isEditMode"
                          variant="secondary"
                          class="mb-2"
                          block
                          @click="sidebar.isEditMode = true">
                    Ändra
                </b-button>
                <SaveWidget v-if="sidebar.isEditMode" :id="sidebar.selected.id" />
            </template>
        </GenericSidebar>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import { mapState } from 'vuex';
    import GenericSidebar from '@/components/GenericSidebar';
    import SaveWidget from '@/views/admin/manage/widgets/SaveWidget';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';

    export default {
        name: 'ListWidgets',
        components: {
            PaginationPageSizeSelect,
            GenericSidebar,
            SaveWidget
        },
        data() {
            return {
                onlyShowActiveWidgets: true,
                sidebar: {
                    selected: null,
                    visible: false,
                    isEditMode: false,
                    fields: [
                        { key: 'title', label: 'Title' },
                        { key: 'dashboardName', label: 'Namn' },
                        { key: 'componentName', label: 'Komponentnamn' },
                        { key: 'cols', label: 'Kolumnstorlek' },
                        { key: 'sortOrder', label: 'Sorteringsordning' },
                        { key: 'displayActiveText', label: 'Aktiv' },
                    ]
                },
                widgets: [],
                fields: [
                    {
                        key: 'title',
                        label: 'Titel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'title', label: 'Titel', sortable: true, sortDirection: 'desc' },
                    {
                        key: 'dashboardName',
                        label: 'Namn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'sortOrder',
                        label: 'Sorteringsordning',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'componentName',
                        label: 'Komponentnamn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'cols',
                        label: 'Kolumnstorlek',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'displayActiveText',
                        label: 'Aktiv',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isBusy: true,
                isReady: false
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user
            }),
            filteredWidgets() {
                // Filter widgets based on `onlyShowActiveWidgets`
                return this.widgets.filter(widget => this.onlyShowActiveWidgets ? widget.isActive : true);
            },
            sortOptions() {
                // Create an options list from our fields.
                return this.fields
                    .filter((x) => x.sortable)
                    .map((x) => {
                        return { text: x.label, value: x.key };
                    });
            }
        },
        async mounted() {
            // Load currentPage and onlyShowActiveWidgets from localStorage if they exist
            const savedPage = localStorage.getItem('widgetsCurrentPage');
            const savedOnlyShowActive = localStorage.getItem('widgetsOnlyShowActive');

            if (savedPage) {
                this.currentPage = parseInt(savedPage, 10);
            }
            if (savedOnlyShowActive !== null) {
                this.onlyShowActiveWidgets = savedOnlyShowActive === 'true';
            }

            // Fetch data and set `isReady` to true when everything is loaded
            await this.getData();
            this.isReady = true;
        },
        watch: {
            // Watch for changes in `filteredWidgets` and update `totalRows` accordingly
            filteredWidgets(newWidgets) {
                this.totalRows = newWidgets.length; 
                this.checkCurrentPage();
            },
            currentPage(newPage) {
                localStorage.setItem('widgetsCurrentPage', newPage);
            },
            onlyShowActiveWidgets(newValue) {
                localStorage.setItem('widgetsOnlyShowActive', newValue);
            }
        },
        methods: {
            async getData() {
                await get('Widget', 'GetAll')
                    .then((x) => {
                        this.widgets = x.data;
                        this.totalRows = this.filteredWidgets.length; 
                        this.isBusy = false;
                    })
                    .catch((x) => {
                        console.log("Error fetching widgets");
                    });
            },
            checkCurrentPage() {
                // Ensure currentPage is within range of total pages based on filtered widgets
                const maxPage = Math.ceil(this.totalRows / this.perPage);
                if (this.currentPage > maxPage) {
                    this.currentPage = maxPage || 1; 
                }
            },
            onFiltered(filteredItems) {
                // Update totalRows and check currentPage based on filtered items
                this.totalRows = filteredItems.length;
                this.checkCurrentPage();
            },
            onContextChanged(ctx) {
                this.checkCurrentPage();
            },
            add() {
                this.sidebar.selected = { id: 0 };
                this.sidebar.visible = true;
                this.sidebar.isEditMode = true;
            },
            edit(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
        }
    };
</script>